'use client';

import { Box, Button, Group, Stack, Text } from '@mantine/core';
import { useWindowScroll } from '@uidotdev/usehooks';
import clsx from 'clsx';
import Link from 'next/link';

import { FrachterLogo } from '@/components/FrachterLogo';
import { env } from '@/data/env';

import s from './NavBar.module.scss';

export const NavBar = () => {
	const [{ y }] = useWindowScroll();

	return (
		<>
			<Box className={clsx(s.root, (y ?? 0) > 150 && s.withBackground)} component="nav" px="md" py="md">
				<Box className={s.content}>
					<Group gap="xs" style={{ justifySelf: 'start' }}>
						<FrachterLogo />
						<Stack gap={0}>
							<Text fw={800} lh={1}>
								frachter
							</Text>
							<Text fw={500} size="xs">
								Let us do the heavy lifting
							</Text>
						</Stack>
					</Group>
					<Text style={{ justifySelf: 'center' }} visibleFrom="sm">
						<strong style={{ color: 'var(--color-brand-secondary)' }}>QuickShip</strong> by frachter
					</Text>

					<Box
						style={{
							alignItems: 'center',
							display: 'grid',
							gap: '1rem',
							gridTemplateColumns: 'auto auto',
							justifySelf: 'end',
						}}
					>
						<Button
							color="var(--color-brand)"
							component={Link}
							href={env.NEXT_PUBLIC_CLERK_SIGN_IN_URL}
							style={{ justifySelf: 'end' }}
							variant="outline"
						>
							Login
						</Button>
					</Box>
				</Box>
			</Box>
		</>
	);
};
