'use client';

import { Box } from '@mantine/core';

import Logo from '@/assets/logo.svg';

import s from './FrachterLogo.module.scss';

export const FrachterLogo = ({ size = 36 }: { size?: number }) => {
	// WARN: with react canary / 19 it seems that svgs are no longer working with svgr
	// it throws Objects are not valid as react child
	return (
		<Box className={s.root} style={{ height: size }}>
			<Logo />
		</Box>
	);
};
