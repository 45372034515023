import(/* webpackMode: "eager" */ "/vercel/path0/apps/frachter/src/app/(public)/(homepage)/assets/HeroImage.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frachter/src/app/(public)/(homepage)/assets/HeroImagePhone.png");
;
import(/* webpackMode: "eager", webpackExports: ["HeroContent"] */ "/vercel/path0/apps/frachter/src/app/(public)/(homepage)/HeroContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavBar"] */ "/vercel/path0/apps/frachter/src/app/(public)/(homepage)/NavBar.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/frachter/src/app/(public)/(homepage)/page.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/vercel/path0/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
