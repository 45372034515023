'use client';

import { Box, Button, Stack, Text } from '@mantine/core';
import Link from 'next/link';

import { env } from '@/data/env';

import s from './HeroContent.module.scss';

export const HeroContent = () => {
	return (
		<>
			<Stack className={s.root}>
				<Text fw={600} size="clamp(22px, 6vw, 46px)" ta="center">
					<span style={{ color: `var(--color-brand-secondary)` }}>Pakete.</span> Einfach versenden.
				</Text>
				<Box ta="center">
					<Text lh={1.4} size="clamp(14px, 3vw, 26px)">
						Der einfachste und günstigste Weg
						<br />
						Deine Versandquoten zu erfüllen.
					</Text>
					<Button
						color="var(--color-brand-secondary)"
						component={Link}
						href={env.NEXT_PUBLIC_CLERK_SIGN_UP_URL}
						maw={300}
						mt="xl"
						size="lg"
					>
						Jetzt kostenlos starten
					</Button>

					<Text mt="xs" size="xs">
						Keine monatlichen Gebühren*
					</Text>
				</Box>
			</Stack>
		</>
	);
};
